<template>
    <PopupBox
        :class="$style['popup']"
        @miss="$emit('miss')"
    >
        <ProfileMenu />
    </PopupBox>
</template>

<script>
import PopupBox from '@@/website/components/common/PopupBox'
import ProfileMenu from '@@/website/components/common/ProfileMenu'

export default {
  name: 'ProfileMenuPopup',

  components: {
    PopupBox,
    ProfileMenu
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.popup {
  width: 128px !important;
  padding: 16px;
  margin: 0 auto;
  transform: translateX(18px);
  position: absolute;
  top: 51px;
  left: 0;
  right: 0;
  @media screen and (max-width: @bp-mobile-small) {
    display: none !important;
  }
}
</style>
