<template>
    <div>
        <div v-if="logo">
            <img
                :class="$style['image']"
                :src="logo"
                alt="logo"
            >
        </div>

        <div>
            {{ title }}
        </div>
    </div>
</template>

<script>
export default {
  name: 'HeaderLogo',

  props: {
    title: {
      type: String,
      default: ''
    },

    logo: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" module>
.image {
  display: block;
  width: auto;
  height: 32px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  padding-right: 12px;
}
</style>
