<template>
    <form
        ref="form"
        :class="{
            [$style['form']]: true,
            [$style['form_roundings_none']]: $simlaweb.settings.roundings === ROUNDINGS.NONE,
            [$style['form_roundings_small']]: $simlaweb.settings.roundings === ROUNDINGS.SMALL,
            [$style['form_roundings_large']]: $simlaweb.settings.roundings === ROUNDINGS.LARGE
        }"
        action="#"
        @submit.prevent="getProducts"
        @click.stop
    >
        <label
            :class="{
                [$style['form__label']]: true,
                [$style['form__label_filled']]: term.length > 0,
                [$style['form__label_focused']]: focused,
            }"
        >
            <input
                ref="input"
                type="search"
                :class="$style['form__input']"
                autocomplete="off"
                @keyup.down.prevent="onInputKeyDown"
                @keydown.9.prevent="onInputKeyDown"
                @keydown.shift.tab="onInputKeyDown"
                @blur="focused = false"
                @focus="focused = true"
                @input="onInput"
            >

            <SvgSpriteSearch :class="$style['form__search-icon']" />

            <span :class="$style['form__placeholder']">
                {{ $t('search') }}
            </span>

            <span
                v-show="term.length > 0"
                :class="$style['form__right-icon']"
                @click="clearInputValue"
            >
                <SvgSpriteCrossOutlined style="display: block" />
            </span>
        </label>

        <span :class="$style['form__close-icon']" @click="close">
            <SvgSpriteCross />
        </span>

        <div
            :class="{
                [$style['form__datalist-wrapper']]: true,
                [$style['form__datalist-wrapper_show']]: showDropdownList
            }"
        >
            <UiScrollbar
                :class="$style['form__datalist']"
                role="combobox"
                show-on-mac
            >
                <div
                    v-for="(item, i) in dropdownList"
                    :key="i"
                >
                    <div
                        v-show="item.elements.length"
                        :class="[$style['form__datalist-title'], 'sw-text']"
                    >
                        {{ item.title }}
                    </div>

                    <div
                        v-for="(option, index) in item.elements"
                        :key="option + index"
                        :ref="'option-' + option.position"
                        :class="$style['form__datalist-option']"
                        role="option"
                        tabindex="0"
                        @keydown.up.prevent="onOptionKeyUp(option.position)"
                        @keydown.down="onOptionKeyDown(option.position)"
                        @keydown.9.prevent="onOptionKeyDown(option.position - 1)"
                        @keydown.shift.tab.prevent="onOptionKeyUp(0)"
                        @keydown.enter="pickOption(option)"
                        @click="pickOption(option)"
                    >
                        <SwImage
                            v-if="Object.keys(option).includes('imageUrl')"
                            :class="$style['form__datalist-option-img']"
                            :src="option.imageUrl"
                            width="-"
                            height="32"
                            alt=""
                        />

                        <div
                            :class="[$style['form__datalist-option-text'], 'sw-text']"
                            v-html="highlightMatches(option.name)"
                        />
                    </div>

                    <div
                        v-if="!item.elements.length && item.name === 'hintList'"
                        :class="$style['form__datalist-not-found']"
                    >
                        {{ $t('search_nothing_found') }}
                    </div>
                </div>
            </UiScrollbar>
        </div>
    </form>
</template>

<script>
import SvgSpriteCross from './SearchForm/sprite-cross.svg'
import SvgSpriteCrossOutlined from './SearchForm/sprite-cross-outlined.svg'
import SvgSpriteSearch from './SearchForm/sprite-search.svg'

import throttle from 'lodash.throttle'

import { GET_PRODUCTS_SEARCH_HINT } from '@@/graphQL/web'
import { ROUNDINGS } from '@@/framework/types'

export default {
  name: 'SearchForm',

  components: {
    SvgSpriteCross,
    SvgSpriteCrossOutlined,
    SvgSpriteSearch
  },

  props: {
    isMock: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    categoriesList: [],
    hintList: [],
    term: '',
    focused: false
  }),

  computed: {
    ROUNDINGS: () => ROUNDINGS,

    showDropdownList () {
      return Boolean(this.term.length > 2 && this.dropdownList.length)
    },

    dropDownElementsCount () {
      return this.categoriesList.length + this.hintList.length
    },

    dropdownList () {
      let counter = 0

      return [{
        title: this.$t('category'),
        elements: this.categoriesList.map(item => ({
          position: counter++,
          name: item.name,
          slug: item.slug
        }))
      }, {
        name: 'hintList',
        title: this.$t('search_result_title'),
        elements: this.hintList.map(item => ({
          position: counter++,
          name: item.name,
          imageUrl: item.imageUrl,
          slug: item.slug
        }))
      }]
    }
  },

  mounted () {
    document.addEventListener('click', this.closePanelHandler)
  },

  destroyed () {
    document.removeEventListener('click', this.closePanelHandler)
  },

  methods: {
    getProducts () {
      if (this.isMock || !this.term) {
        return
      }

      this.$store.commit('catalog/term', this.term)
      this.clearInputValue()
      this.close()

      const query = { ...this.$route.query }
      delete query.page

      this.$router.push({
        path: '/search',
        query: { ...query, q: this.$store.state.catalog.term }
      })
    },

    throttleHints: throttle(function (value) {
      this.getProductsSearchHint(value)
    }, 1000),

    async getProductsSearchHint (firstLetters) {
      await this.$apollo
        .query({
          query: GET_PRODUCTS_SEARCH_HINT,
          variables: {
            firstLetters
          }
        })
        .then((res) => {
          if (!res) {
            return
          }

          this.hintList = res.data.productNamesForSearchHint
        })
    },

    onInput (event) {
      const value = event.target.value
      this.term = value

      if (!this.isMock && value.length > 2) {
        this.throttleHints(value)
      }
    },

    clearInputValue () {
      this.term = ''
      this.$refs.input.value = ''
    },

    /** @param {MouseEvent} event */
    closePanelHandler (event) {
      if (!this.$refs.form.contains(event.target)) {
        this.close()
      }
    },

    onInputKeyDown () {
      if (this.dropDownElementsCount) {
        this.$refs['option-0'][0].focus()
      }
    },

    onOptionKeyUp (index) {
      if (index) {
        const prevIndex = Math.max(0, index - 1)
        const ref = 'option-' + prevIndex
        this.$refs[ref][0].focus()
      } else {
        this.$refs.input.focus()
      }
    },

    onOptionKeyDown (index) {
      const nextIndex = Math.min(this.dropDownElementsCount - 1, index + 1)
      const ref = 'option-' + nextIndex
      this.$refs[ref][0].focus()
    },

    async pickOption (option) {
      if (this.isMock || !option.slug) {
        return
      }

      await this.$router.push({
        name: 'catalog-details-slug',
        params: { slug: option.slug }
      })

      this.close()
    },

    highlightMatches (text) {
      const match = text.toLowerCase().indexOf(this.term.toLowerCase())

      if (~match) {
        const textSubstr = text.substr(match, this.term.length)
        return text.replace(textSubstr, '<span>' + textSubstr + '</span>')
      }

      return text
    },

    close () {
      this.$store.commit('modals/close', 'search')
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";
@import (reference) "./assets/Web.less";

.form {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #FFFFFF;
  font-family: var(--sw-font-texts);
  position: relative;

  &__label {
    position: relative;
    display: block;
    width: 568px;
    height: 56px;
    padding: 0 48px 0 44px;
    background-color: #FFFFFF;

    @media screen and (max-width: @bp-mobile-small) {
      width: 100%;
      border: 1px solid @grey-600;

      &:hover {
        border-color: @grey-800;
      }
    }

    &_focused {
      @media screen and (max-width: @bp-mobile-small) {
        border-color: @brand-500;
        box-shadow: 0 0 0 2px @brand-200;
      }
    }
  }

  &__label_filled &__search-icon,
  &__label_focused &__search-icon {
    color: @black-text;
  }

  &__input {
    position: relative;
    top: 16px;
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: @black-text;
    background-color: #FFFFFF;
    border: none;
    outline: none;

    // delete cross inside input
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }

    @media screen and (max-width: @bp-mobile-small) {
      top: 24px;
    }
  }

  &__search-icon {
    color: @grey-700;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
  }

  &__placeholder {
    position: absolute;
    top: 15px;
    left: 0;

    display: inline-block;
    width: calc(100% - 48px);
    padding-left: 44px;

    color: @grey-700;
    font-size: 16px;
    line-height: 24px;

    transition: ease-in-out 0.3s;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
  }

  &__label_filled &__placeholder {
    display: none;
  }

  &__label_filled &__placeholder,
  &__label_focused &__placeholder {
    @media screen and (max-width: @bp-mobile-small) {
      display: inline-block;
      font-size: 12px;
      line-height: 16px;
      transform: translateY(-10px);
    }
  }

  &__right-icon {
    display: none;
    color: #afb9c3;
    cursor: pointer;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    right: 17px;
    z-index: 2;

    @media screen and (max-width: @bp-mobile-small) {
      display: block;
    }
  }

  &__close-icon {
    display: block;
    padding: 8px;
    color: #14142a;
    cursor: pointer;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    right: 24px;

    @media screen and (max-width: @bp-mobile-small) {
      display: none;
    }

    svg {
      display: block;
    }
  }

  &__datalist {
    max-height: 460px;

    @media screen and (max-width: @bp-mobile-small) {
      max-height: calc(100vh - 190px);
    }

    &-wrapper {
      position: fixed;
      top: 80px;
      display: none;
      width: 568px;
      padding-bottom: 16px;
      background-color: #FFFFFF;
      border-radius: 0 0 8px 8px;
      overflow: hidden;

      @media screen and (max-width: @bp-mobile-small) {
        position: absolute;
        top: 84px;
        width: calc(100% - 32px);
      }

      &_show {
        display: block;
      }
    }

    &-title {
      display: block;
      padding: 16px 24px 8px 24px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      text-transform: uppercase;
      color: @grey-800;
      cursor: default;
    }

    &-option {
      display: flex;
      align-items: center;
      padding: 8px 24px;
      font-size: 16px;
      line-height: 24px;
      color: @black-text;
      background-color: #FFFFFF;
      cursor: pointer;

      &:hover {
        background-color: @grey-200;
      }

      &:focus {
        background-color: @grey-200;
      }

      &:focus-visible {
        outline: none;
      }

      &:active {
        background-color: @grey-300;
      }

      &-img {
        max-width: 32px !important;
        width: auto;
        height: 32px !important;
        padding: 0;
        margin-right: 8px;
        margin-left: 0;
        object-fit: cover;
      }

      &-text {
        color: @grey-800;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        span {
          color: @black-text;
        }
      }
    }

    &-not-found {
      padding: 20px 24px 8px 24px;
      color: @grey-800;
      font-size: 16px;
      line-height: 24px;

      @media screen and (max-width: @bp-mobile-small) {
        padding: 0;
        font-size: 14px;
        line-height: 20px;
        color: @black-text;
      }
    }
  }

  &_roundings_none &__label { border-radius: 0; }
  &_roundings_small &__label { border-radius: 8px; }
  &_roundings_large &__label { border-radius: 32px; }
}
</style>
