<template>
    <SwLink
        v-if="path.includes('//')"
        :href="path"
        v-bind="$attrs"
        target="_blank"
        black
    >
        {{ text }}
    </SwLink>

    <SwLink
        v-else
        :to="{ path }"
        v-bind="$attrs"
        black
    >
        {{ text }}
    </SwLink>
</template>

<script>
export default {
  name: 'CustomLink',

  props: {
    text: {
      type: String,
      default: ''
    },

    path: {
      type: String,
      default: ''
    }
  }
}
</script>
