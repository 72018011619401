<template>
    <div
        :class="$style['container']"
        :style="{
            background: hexToRgba(
                bgColor,
                parseFloat(bgOpacity)
            )
        }"
    >
        <SwContainer
            :bg-color="bgColor"
            :bg-opacity="bgOpacity"
            :class="{
                [$style['container']]: true,
                [$style['container_fixed']]: isFixed,
            }"
            v-bind="$attrs"
            fluid
            py0
        >
            <slot />
        </SwContainer>
    </div>
</template>

<script>
export default {
  name: 'HeaderContainer',

  props: {
    bgColor: {
      type: String,
      default: ''
    },

    bgOpacity: {
      type: String,
      default: '0%'
    },

    fixed: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    scrollTop: 0
  }),

  computed: {
    isFixed () {
      // @TODO: Доработать проверку нахождения в превью
      return this.fixed && this.scrollTop && !this.$route.name.startsWith('siteCode-preview')
    }
  },

  mounted () {
    if (this.fixed) {
      document.addEventListener('scroll', this.onScroll)
    }
  },

  destroyed () {
    document.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    onScroll () {
      this.scrollTop = window.scrollY
    },

    hexToRgba (hexColor, opacity) {
      hexColor = hexColor.replace('#', '')

      const r = parseInt(hexColor.substring(0, 2), 16)
      const g = parseInt(hexColor.substring(2, 4), 16)
      const b = parseInt(hexColor.substring(4, 6), 16)
      const a = opacity / 100

      return `rgba( ${r}, ${g}, ${b}, ${a} )`
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.container {
  min-height: 80px;
  transition: ease-out 0.2s;
  position: relative;
  z-index: 9;
  @media screen and (max-width: @bp-mobile-small) {
    min-height: 64px;
  }

  &_fixed {
    display: flex;
    width: 100%;
    min-height: 60px;
    align-items: center;
    position: fixed !important;
    z-index: 5;
  }
}
</style>
