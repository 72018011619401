<template>
    <HeaderContainer
        :bg-color="config.background_color_all"
        :bg-opacity="config.background_opacity_all"
        :class="$style['header']"
        :fixed="fixed"
    >
        <div :class="$style['header__content']">
            <div
                :class="{
                    [$style['header__logo']]: true,
                    [$style['header__logo_center']]: styleKey === VARIANTS.LOGO_CENTER,
                }"
            >
                <NuxtLink
                    v-if="logo"
                    :class="$style['header__logo-link']"
                    :to="{ path: '/' }"
                >
                    <img
                        :class="$style['header__logo-image']"
                        :src="logo"
                        alt=""
                    >
                </NuxtLink>
            </div>

            <div :class="$style['header__menu']">
                <HeaderMenu
                    :menu="menu"
                    :inverted="inverted"
                    :align="{
                        [VARIANTS.MENU_CENTER]: 'center',
                        [VARIANTS.MENU_LEFT]: 'left',
                        [VARIANTS.MENU_RIGHT]: 'right',
                    }[styleKey] || 'left'"
                />
            </div>

            <div :class="$style['header__icons']">
                <div ref="search">
                    <SwButton
                        :inverted="inverted"
                        borderless
                        light
                        small
                        transparent
                        @click.stop="
                            open('search');
                            $nextTick(() => {
                                $refs.search.querySelectorAll('input').forEach(el => el.focus())
                            });
                        "
                    >
                        <template #leading-icon>
                            <SvgSpriteSearch />
                        </template>
                    </SwButton>

                    <SearchPanel
                        v-show="$store.getters['modals/opened']('search')"
                        :class="$style['desktop']"
                    />

                    <SearchSidebar
                        :class="$style['mobile']"
                        :logo="logo"
                    />
                </div>

                <CartButton
                    :count="orderTotalPrice && orderTotalPrice.countOffer"
                    :inverted="inverted"
                />
            </div>

            <div :class="$style['header__login']">
                <SwButton
                    :class="$style['header__login-button']"
                    :inverted="inverted"
                    borderless
                    light
                    small
                    transparent
                    @click.stop="onLoginClick"
                >
                    <template #leading-icon>
                        <SvgSpriteUser />
                    </template>

                    {{
                        authenticated
                            ? $store.state.user.firstName
                                ? $store.state.user.firstName
                                : $t('user_profile_label')
                            : $t('log_in')
                    }}
                </SwButton>

                <ProfileMenuPopup
                    v-show="profilePopupOpened"
                    @miss="profilePopupOpened = false"
                />
            </div>

            <div :class="$style['header__burger']">
                <div
                    :class="{
                        [$style['header__burger-button']]: true,
                        [$style['header__burger-button_inverted']]: inverted,
                    }"
                    @click="open('mainMenu')"
                >
                    <SvgSpriteBurger />
                </div>
            </div>
        </div>

        <MainMenuSidebar
            :logo="logo"
            :menu="menu"
        />

        <ProfileMenuSidebar />
    </HeaderContainer>
</template>

<script>
import CartButton from './BlockHeader/CartButton'
import MainMenuSidebar from './BlockHeader/MainMenuSidebar'
import HeaderContainer from './BlockHeader/HeaderContainer'
import HeaderMenu from './BlockHeader/HeaderMenu'
import ProfileMenuPopup from './BlockHeader/ProfileMenuPopup'
import ProfileMenuSidebar from './BlockHeader/ProfileMenuSidebar'
import SearchPanel from './BlockHeader/SearchPanel'
import SearchSidebar from './BlockHeader/SearchSidebar'
import SvgSpriteBurger from './BlockHeader/sprite-burger.svg'
import SvgSpriteSearch from './BlockHeader/sprite-search.svg'
import SvgSpriteUser from './BlockHeader/sprite-user.svg'

import { GET_ORDER_TOTAL } from '@@/graphQL/web'

const VARIANTS = {
  LOGO_CENTER: 'logo_in_the_center_items_on_the_left',
  MENU_CENTER: 'menu_items_in_the_middle',
  MENU_LEFT: 'logo_and_menu_items_on_the_left',
  MENU_RIGHT: 'logo_on_the_left_items_on_the_right'
}

export default {
  name: 'BlockHeader',

  components: {
    CartButton,
    HeaderContainer,
    HeaderMenu,
    MainMenuSidebar,
    ProfileMenuPopup,
    ProfileMenuSidebar,
    SearchPanel,
    SearchSidebar,
    SvgSpriteBurger,
    SvgSpriteSearch,
    SvgSpriteUser
  },

  props: {
    styleKey: {
      type: String,
      default: ''
    },

    config: {
      type: Object,
      default: () => ({})
    },

    fixed: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    orderTotalPrice: null,
    profilePopupOpened: false
  }),

  computed: {
    VARIANTS: () => VARIANTS,

    /** @return {string} */
    logo () {
      return this.config.logo_image_all
    },

    /** @return {Array} */
    menu () {
      return this.config.link_items_all
    },

    authenticated () {
      return Boolean(this.$store.state.user.authenticated)
    },

    /** @return {boolean} */
    inverted () {
      return this.config.inverted_font_color_header_all
    }
  },

  apollo: {
    orderTotalPrice: {
      query: GET_ORDER_TOTAL,
      variables () {
        return {
          siteCode: this.$store.state.siteCode,
          code: this.$store.state.checkout.delivery.code,
          bonuses: this.$store.state.checkout.bonuses,
        }
      }
    }
  },

  methods: {
    onLoginClick () {
      if (this.$store.state.admin) {
        this.open('forbidden')
        return
      }

      if (this.authenticated) {
        this.profilePopupOpened = true
      } else {
        this.open('login')
      }
    },

    open (name) {
      this.$store.commit('modals/open', name)
    },

    close (name) {
      this.$store.commit('modals/close', name)
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.header {
  display: block;
  z-index: 5000;

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    position: relative;
    @media screen and (max-width: @bp-mobile-big) {
      justify-content: flex-start;
    }
  }

  &__logo &__logo_center {
    order: 3;
    margin: auto;
    @media screen and (max-width: @bp-mobile-big) {
      margin: 0;
    }
  }

  &__logo-link {
    display: block;
    max-height: 80px;
  }

  &__logo-image {
    display: block;
    width: auto;
    height: 50px;
    max-width: 160px;
    max-height: 50px;
    border: none;
    border-radius: 0;
    object-fit: contain;
    margin: 0;
  }

  &__menu {
    display: flex;
    flex-grow: 1;
    padding-left: 12px;
    padding-right: 12px;
    order: 2;
    @media screen and (max-width: @bp-mobile-big) {
      display: none;
    }

    svg {
      height: 24px;
    }
  }

  &__icons {
    order: 3;
    display: flex;
    align-items: center;
    @media screen and (max-width: @bp-mobile-big) {
      margin-left: auto;
      margin-right: 12px;
    }
  }

  &__login {
    order: 4;
    font-weight: 600;
    position: relative;

    @media screen and (max-width: @bp-mobile-big) {
      display: none;
    }
  }

  &__login-button {
    font-weight: 400;
    margin-left: 36px;
    position: relative;

    &:hover {
      cursor: pointer;
    }
  }

  &__burger {
    order: 5;
    @media screen and (min-width: @bp-mobile-big) {
      display: none;
    }
  }

  &__burger-button {
    color: var(--sw-color-main-500);
    cursor: pointer;

    &_inverted {
      color: #FFFFFF;
    }

    svg {
      display: block;
    }
  }
}

.desktop {
  display: inherit;
  @media screen and (max-width: @bp-mobile-small) {
    display: none;
  }
}

.mobile {
  display: none;
  @media screen and (max-width: @bp-mobile-small) {
    display: inherit;
  }
}
</style>
