
























import Vue from 'vue'

export default Vue.extend({
  name: 'ProfileMenu',

  methods: {
    logout () {
      // @TODO: Логику перехода переместить в beforeRouteEnter страниц профиля пользователя
      if (this.$route.name?.includes('user')) {
        this.$router.push('/')
      }

      this.$store.dispatch('logout')
    }
  }
})
