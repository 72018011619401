var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('NuxtLink',{class:( _obj = {}, _obj[_vm.$style['button']] = true, _obj[_vm.$style['button__roundings_none']] = _vm.$simlaweb.settings.roundings === _vm.ROUNDINGS.NONE, _obj[_vm.$style['button__roundings_small']] = _vm.$simlaweb.settings.roundings === _vm.ROUNDINGS.SMALL, _obj[_vm.$style['button__roundings_large']] = _vm.$simlaweb.settings.roundings === _vm.ROUNDINGS.LARGE, _obj ),style:(_vm.inverted
        ? {
            '--sw-color-main-500': 'rgba(255, 255, 255, 1)',
            '--sw-color-main-600': 'rgba(255, 255, 255, 0.8)',
            '--sw-color-main-700': 'rgba(255, 255, 255, 0.6)',
            '--sw-color-main-300': 'rgba(255, 255, 255, 0.4)',
            '--sw-color-main-200': 'rgba(255, 255, 255, 0.2)',
            '--sw-color-main-000': 'rgba(255, 255, 255, 0)'
        }
        : {}),attrs:{"to":{ name: 'cart' }}},[_c('SwBadge',{attrs:{"inverted":_vm.inverted},scopedSlots:_vm._u([(_vm.count)?{key:"count",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.count)+"\n        ")]},proxy:true}:null],null,true)},[_c('SvgSpriteCart',{class:_vm.$style['button__icon']})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }