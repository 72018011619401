<template>
    <NuxtLink
        :to="{ name: 'cart' }"
        :class="{
            [$style['button']]: true,
            [$style['button__roundings_none']]: $simlaweb.settings.roundings === ROUNDINGS.NONE,
            [$style['button__roundings_small']]: $simlaweb.settings.roundings === ROUNDINGS.SMALL,
            [$style['button__roundings_large']]: $simlaweb.settings.roundings === ROUNDINGS.LARGE,
        }"
        :style="inverted
            ? {
                '--sw-color-main-500': 'rgba(255, 255, 255, 1)',
                '--sw-color-main-600': 'rgba(255, 255, 255, 0.8)',
                '--sw-color-main-700': 'rgba(255, 255, 255, 0.6)',
                '--sw-color-main-300': 'rgba(255, 255, 255, 0.4)',
                '--sw-color-main-200': 'rgba(255, 255, 255, 0.2)',
                '--sw-color-main-000': 'rgba(255, 255, 255, 0)'
            }
            : {}
        "
    >
        <SwBadge :inverted="inverted">
            <SvgSpriteCart :class="$style['button__icon']" />

            <template v-if="count" #count>
                {{ count }}
            </template>
        </SwBadge>
    </NuxtLink>
</template>

<script>
import SvgSpriteCart from './CartButton/sprite-cart.svg'
import { ROUNDINGS } from '@@/framework/types'

export default {
  name: 'CartButton',

  components: {
    SvgSpriteCart
  },

  props: {
    count: {
      type: Number,
      default: 0
    },

    inverted: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ROUNDINGS: () => ROUNDINGS
  }
}
</script>

<style lang="less" module>
.button {
  padding: 9px;

  &__icon { color: var(--sw-color-main-500); }

  &:hover { background: var(--sw-color-main-200); }
  &:active { background: var(--sw-color-main-000); }

  &__roundings_none { border-radius: 0; }
  &__roundings_small { border-radius: 8px; }
  &__roundings_large { border-radius: 32px; }
}
</style>
