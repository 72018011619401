var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style['container'],style:({
        background: _vm.hexToRgba(
            _vm.bgColor,
            parseFloat(_vm.bgOpacity)
        )
    })},[_c('SwContainer',_vm._b({class:( _obj = {}, _obj[_vm.$style['container']] = true, _obj[_vm.$style['container_fixed']] = _vm.isFixed, _obj ),attrs:{"bg-color":_vm.bgColor,"bg-opacity":_vm.bgOpacity,"fluid":"","py0":""}},'SwContainer',_vm.$attrs,false),[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }