<template>
    <div :class="$style['popup-box']">
        <slot />
    </div>
</template>

<script>
export default {
  name: 'PopupBox',

  mounted () {
    /** @param {Event} event */
    const onMissClick = (event) => {
      if (this.$el !== event.target) {
        this.$emit('miss')
      }
    }

    document.addEventListener('click', onMissClick)
    this.$once('hook:destroyed', () => {
      document.removeEventListener('click', onMissClick)
    })
  }
}
</script>

<style lang="less" module>
.popup-box {
  display: block;
  width: max-content;
  background: #FFFFFF;
  border: none;
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(31, 31, 46, 0.16);
  position: absolute;
  z-index: 100;

  &::before {
    content: "";
    width: 0;
    height: 0;
    border-bottom: 10px solid #FFFFFF;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 12px solid transparent;
    margin-left: -10px;
    position: absolute;
    top: -22px;
    left: 50%;
  }
}
</style>
