<template>
    <PopupBox :class="$style['popup']" @miss="$emit('miss')">
        <MenuItem
            v-for="(item, index) in menu"
            :key="index"
            :name="item.name"
            :link="item.url"
        />
    </PopupBox>
</template>

<script>
import MenuItem from '@@/website/components/common/MenuItem'
import PopupBox from '@@/website/components/common/PopupBox'

export default {
  name: 'MorePopup',

  components: {
    MenuItem,
    PopupBox
  },

  props: {
    menu: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="less" module>
.popup {
  padding: 4px;
  border: 1px solid #FFFFFF !important;
  transform: translateX(calc(50% - 21px));
  position: absolute;
  top: 32px;
  right: 0;
}
</style>
