<template>
    <div :class="$style['panel']">
        <SearchForm :class="$style['form']" :is-mock="!!$store.state.admin" />
    </div>
</template>

<script>
import SearchForm from '@@/website/components/blocks/BlockHeader/common/SearchForm'

export default {
  name: 'SearchPanel',

  components: {
    SearchForm
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.panel {
  width: 100%;
  overflow: hidden;
  touch-action: auto;
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  right: 0;

  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 12;
    background: @black-title;
    opacity: 0.5;
  }
}

.form {
  padding: 12px;
  z-index: 13;
}
</style>
