var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('HeaderContainer',{class:_vm.$style['header'],attrs:{"bg-color":_vm.config.background_color_all,"bg-opacity":_vm.config.background_opacity_all,"fixed":_vm.fixed}},[_c('div',{class:_vm.$style['header__content']},[_c('div',{class:( _obj = {}, _obj[_vm.$style['header__logo']] = true, _obj[_vm.$style['header__logo_center']] = _vm.styleKey === _vm.VARIANTS.LOGO_CENTER, _obj )},[(_vm.logo)?_c('NuxtLink',{class:_vm.$style['header__logo-link'],attrs:{"to":{ path: '/' }}},[_c('img',{class:_vm.$style['header__logo-image'],attrs:{"src":_vm.logo,"alt":""}})]):_vm._e()],1),_vm._v(" "),_c('div',{class:_vm.$style['header__menu']},[_c('HeaderMenu',{attrs:{"menu":_vm.menu,"inverted":_vm.inverted,"align":( _obj$1 = {}, _obj$1[_vm.VARIANTS.MENU_CENTER] = 'center', _obj$1[_vm.VARIANTS.MENU_LEFT] = 'left', _obj$1[_vm.VARIANTS.MENU_RIGHT] = 'right', _obj$1 )[_vm.styleKey] || 'left'}})],1),_vm._v(" "),_c('div',{class:_vm.$style['header__icons']},[_c('div',{ref:"search"},[_c('SwButton',{attrs:{"inverted":_vm.inverted,"borderless":"","light":"","small":"","transparent":""},on:{"click":function($event){$event.stopPropagation();_vm.open('search');
                        _vm.$nextTick(function () {
                            _vm.$refs.search.querySelectorAll('input').forEach(function (el) { return el.focus(); })
                        });}},scopedSlots:_vm._u([{key:"leading-icon",fn:function(){return [_c('SvgSpriteSearch')]},proxy:true}])}),_vm._v(" "),_c('SearchPanel',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.getters['modals/opened']('search')),expression:"$store.getters['modals/opened']('search')"}],class:_vm.$style['desktop']}),_vm._v(" "),_c('SearchSidebar',{class:_vm.$style['mobile'],attrs:{"logo":_vm.logo}})],1),_vm._v(" "),_c('CartButton',{attrs:{"count":_vm.orderTotalPrice && _vm.orderTotalPrice.countOffer,"inverted":_vm.inverted}})],1),_vm._v(" "),_c('div',{class:_vm.$style['header__login']},[_c('SwButton',{class:_vm.$style['header__login-button'],attrs:{"inverted":_vm.inverted,"borderless":"","light":"","small":"","transparent":""},on:{"click":function($event){$event.stopPropagation();return _vm.onLoginClick.apply(null, arguments)}},scopedSlots:_vm._u([{key:"leading-icon",fn:function(){return [_c('SvgSpriteUser')]},proxy:true}])},[_vm._v("\n\n                "+_vm._s(_vm.authenticated
                        ? _vm.$store.state.user.firstName
                            ? _vm.$store.state.user.firstName
                            : _vm.$t('user_profile_label')
                        : _vm.$t('log_in'))+"\n            ")]),_vm._v(" "),_c('ProfileMenuPopup',{directives:[{name:"show",rawName:"v-show",value:(_vm.profilePopupOpened),expression:"profilePopupOpened"}],on:{"miss":function($event){_vm.profilePopupOpened = false}}})],1),_vm._v(" "),_c('div',{class:_vm.$style['header__burger']},[_c('div',{class:( _obj$2 = {}, _obj$2[_vm.$style['header__burger-button']] = true, _obj$2[_vm.$style['header__burger-button_inverted']] = _vm.inverted, _obj$2 ),on:{"click":function($event){return _vm.open('mainMenu')}}},[_c('SvgSpriteBurger')],1)])]),_vm._v(" "),_c('MainMenuSidebar',{attrs:{"logo":_vm.logo,"menu":_vm.menu}}),_vm._v(" "),_c('ProfileMenuSidebar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }