<template>
    <div
        :class="{
            [$style['button']]: true,
            [$style['button_active']]: active,
            [$style['button_inverted']]: inverted,
        }"
        v-on="$listeners"
    >
        <SvgSpriteMore />
    </div>
</template>

<script>
import SvgSpriteMore from './MoreButton/sprite-more.svg'

export default {
  name: 'MoreButton',

  components: {
    SvgSpriteMore
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },

    inverted: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.button {
  display: flex;
  align-items: center;
  color: @black-text;
  cursor: pointer;
  position: relative;

  &:hover {
    color: var(--sw-color-main-700);
  }

  &_active,
  &:active {
    color: var(--sw-color-main-600);
  }

  &&_inverted {
    color: #FFFFFF
  }
}
</style>
