<template>
    <div :class="$style['item']">
        <SwLink
            v-if="!link.includes('//')"
            :to="link ? link : '/'"
            :class="$style['item__link']"
            :inverted="inverted"
            black
        >
            {{ name }}
        </SwLink>

        <SwLink
            v-else
            :href="link"
            :class="$style['item__link']"
            :inverted="inverted"
            target="_blank"
            black
        >
            {{ name }}
        </SwLink>
    </div>
</template>

<script>
export default {
  name: 'MenuItem',

  props: {
    name: {
      type: String,
      default: ''
    },

    link: {
      type: String,
      default: ''
    },

    inverted: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.item {
  display: block;
  border-radius: 4px;
  padding: 6px 8px;

  &:hover {
    background: rgba(14, 130, 238, 0.05);
  }

  &__link:global(.sw-link) {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: @black-500;
    text-decoration: none;
  }

  &:hover&__link {
    color: var(--sw-color-main-700);
  }
}
</style>
