<template>
    <div @click.stop>
        <SwModalSidebar :opened="$store.getters['modals/opened']('search')">
            <SidebarHeader
                :class="$style['header']"
                @close="$store.commit('modals/close', 'search')"
            >
                <HeaderLogo
                    :class="$style['logo']"
                    :logo="logo"
                />
            </SidebarHeader>

            <div :class="[$style['separator'], 'sw-separator']" />

            <SearchForm :class="$style['search']" :is-mock="!!$store.state.admin" />
        </SwModalSidebar>
    </div>
</template>

<script>
import HeaderLogo from './common/HeaderLogo'
import SearchForm from './common/SearchForm'
import SidebarHeader from '@@/website/components/common/SidebarHeader'

export default {
  name: 'SearchSidebar',

  components: {
    HeaderLogo,
    SearchForm,
    SidebarHeader
  },

  props: {
    logo: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" module>
.header {
  background-color: #FFFFFF;
}

.header > h3 {
  justify-content: flex-start !important;
}

.logo {
  display: flex;
  width: 100%;
  height: 32px;
}

.separator:global(.sw-separator) {
  margin: 0;
}

.search {
  padding: 16px;
}
</style>
