<template>
    <SwModalSidebar :opened="$store.getters['modals/opened']('profileMenu')">
        <SidebarHeader
            :class="$style['header']"
            @close="close('profileMenu')"
        >
            <template #icon>
                <span :class="$style['link']" @click="goBack">
                    <SvgSpriteArrowLeft />
                </span>
            </template>

            <div class="sw-text">
                {{ $t('user_profile_label') }}
            </div>
        </SidebarHeader>

        <div :class="$style['menu']">
            <div :class="$style['menu__item']">
                <SwLink to="/user" black>
                    {{ $t('account_general') }}
                </SwLink>
            </div>

            <div :class="$style['menu__item']">
                <SwLink to="/user/orders" black>
                    {{ $t('account_orders') }}
                </SwLink>
            </div>

            <div class="sw-separator" />

            <div :class="$style['menu__item']">
                <SwLink :class="$style['logout']" @click="logout">
                    {{ $t('account_exit') }}
                </SwLink>
            </div>
        </div>
    </SwModalSidebar>
</template>

<script>
import SvgSpriteArrowLeft from './ProfileSidebar/sprite-arrow-left.svg'
import SidebarHeader from '@@/website/components/common/SidebarHeader'

export default {
  name: 'ProfileSidebar',

  components: {
    SvgSpriteArrowLeft,
    SidebarHeader
  },

  methods: {
    async logout () {
      await this.$store.dispatch('logout')
      this.close('profileMenu')
    },

    goBack () {
      this.close('profileMenu')
      this.open('mainMenu')
    },

    open (name) {
      this.$store.commit('modals/open', name)
    },

    close (name) {
      this.$store.commit('modals/close', name)
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";
@import (reference) "./assets/Web.less";

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.link {
  color: #1F1F2E;
  cursor: pointer;

  svg {
    margin-left: 3px;
    margin-right: 16px;
    @media screen and (max-width: @bp-mobile-small) {
      margin-right: 0;
    }
  }
}

.menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 8px 24px 32px;
  @media screen and (max-width: @bp-mobile-small) {
    padding: 16px 16px 40px;
  }

  &__item {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    & + & {
      margin-top: 20px;
    }
  }

  :global(.sw-separator) {
    margin: 20px 0;
  }
}

.logout:global(.sw-link) {
  --sw-link-color-main: @red-500;
  --sw-link-color-hover: @red-300;
  --sw-link-color-visited: @red-500;
}
</style>
