<template>
    <SwModalSidebar
        :opened="$store.getters['modals/opened']('mainMenu')"
        @click.self.stop="close('mainMenu')"
    >
        <SidebarHeader @close="close('mainMenu')">
            <HeaderLogo
                :class="$style['logo-desktop']"
                :title="$t('text_menu')"
            />

            <HeaderLogo
                :class="$style['logo-mobile']"
                :logo="logo"
            />
        </SidebarHeader>

        <div :class="$style['menu']">
            <div
                v-for="(item, index) in menu"
                :key="index"
                :class="$style['menu__item']"
            >
                <CustomLink
                    :path="item.url"
                    :text="item.name"
                />
            </div>

            <div class="sw-separator" />

            <div :class="[$style['menu__item'], $style['menu__item_capitalized']]">
                <SwLink
                    :black="authenticated"
                    @click="onLoginClick"
                >
                    {{
                        authenticated
                            ? $store.state.user.firstName
                                ? $store.state.user.firstName
                                : $t('user_profile_label')
                            : $t('log_in')
                    }}
                </SwLink>
            </div>
        </div>
    </SwModalSidebar>
</template>

<script>
import CustomLink from '@@/website/components/common/CustomLink'
import HeaderLogo from './common/HeaderLogo'
import SidebarHeader from '@@/website/components/common/SidebarHeader'

export default {
  name: 'MainMenuSidebar',

  components: {
    CustomLink,
    HeaderLogo,
    SidebarHeader
  },

  props: {
    logo: {
      type: String,
      default: ''
    },

    menu: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    authenticated () {
      return Boolean(this.$store.state.user.authenticated)
    }
  },

  methods: {
    onLoginClick () {
      this.close('mainMenu')

      if (this.authenticated) {
        this.open('profileMenu')
      } else {
        this.open('login')
      }
    },

    open (name) {
      this.$store.commit('modals/open', name)
    },

    close (name) {
      this.$store.commit('modals/close', name)
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.logo-desktop {
  display: flex;
  width: 100%;
  height: 32px;
  @media screen and (max-width: @bp-mobile-small) {
    display: none;
  }
}

.logo-mobile {
  display: none;
  width: 100%;
  height: 32px;
  @media screen and (max-width: @bp-mobile-small) {
    display: flex;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 8px 24px 32px;
  @media screen and (max-width: @bp-mobile-small) {
    padding: 16px 16px 40px;
  }

  &__item {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    & + & {
      margin-top: 20px;
    }

    &_capitalized::first-letter {
      text-transform: capitalize;
    }
  }

  :global(.sw-separator) {
    margin: 20px 0;
  }
}
</style>
