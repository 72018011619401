<template>
    <div :class="[$style['menu'], $style[`menu_align_${align}`]]">
        <div :class="$style['menu__row']">
            <div
                v-for="(link, index) in menu.slice(0, visibleItemsCount)"
                :key="index + '-' + link.position"
                :class="$style['menu__item']"
            >
                <CustomLink
                    :path="link.url"
                    :text="link.name"
                    :class="$style['menu__link']"
                    :inverted="inverted"
                />
            </div>

            <div
                v-if="visibleItemsCount < menu.length"
                ref="more"
                :class="$style['menu__item']"
            >
                <MoreButton
                    :active="popupOpened"
                    :inverted="inverted"
                    @click.stop="popupOpened = true"
                />

                <MorePopup
                    v-show="popupOpened"
                    :menu="menu.slice(visibleItemsCount)"
                    @miss="popupOpened = false"
                />
            </div>
        </div>
    </div>
</template>

<script>
import CustomLink from '@@/website/components/common/CustomLink'
import MoreButton from './HeaderMenu/MoreButton'
import MorePopup from './HeaderMenu/MorePopup'

export default {
  name: 'HeaderMenu',

  components: {
    CustomLink,
    MoreButton,
    MorePopup
  },

  props: {
    menu: {
      type: Array,
      default: () => []
    },

    inverted: {
      type: Boolean,
      default: false
    },

    align: {
      type: String,
      default: 'left'
    }
  },

  data: () => ({
    popupOpened: false,
    visibleItemsCount: 0
  }),

  mounted () {
    const adjust = async (containerWidth) => {
      this.visibleItemsCount = this.menu.length

      await this.$nextTick()
      await this.$nextTick()

      const items = [...this.$el.querySelectorAll(`.${this.$style['menu__item']}`)]
      const moreButtonWidth = 12 + 18 + 12 // на основе размера иконки и отступов
      const padding = 12 + 12
      let totalWidth = 0

      for (let i = 0; i < items.length; i++) {
        const style = window.getComputedStyle(items[i], null)
        const width = parseFloat(style.width)
        if (totalWidth + width > containerWidth - padding - moreButtonWidth) {
          this.visibleItemsCount = i
          break
        }
        totalWidth += width
      }
    }

    const observer = new ResizeObserver((entries) => {
      entries.forEach(entry => adjust(entry.contentRect.width))
    })

    observer.observe(this.$el)
    this.$once('hook:beforeDestroy', () => {
      observer.disconnect()
    })

    adjust(this.$el.clientWidth)
  }
}
</script>

<style lang="less" module>
.menu {
  display: flex;
  width: 100%;

  &_align {
    &_center { justify-content: center; }
    &_right  { justify-content: flex-end; }
  }

  &__row {
    display: flex;
    margin-left: -12px;
    margin-right: -12px;
  }

  &__item {
    display: inline-flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    position: relative;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__link:global(.sw-link) {
    display: inline-block;
    max-width: 150px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
